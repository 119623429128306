<template>
  <div class="lacrimalirrigation">
    <h3 style="padding-top: 0.2rem;">泪道冲洗</h3>
    <div class="nr">
      <p class="yb">OD</p>
      <div>
        <ul>
          <li>
            <p>上冲：</p>
            <div>
              <div style="display: flex;align-items: center;justify-content: start; padding-bottom: 10px;">
                <span>下返</span>
                <el-select class="sr" v-model="postdata.S_OP_Json.S_OP_SLP_OD_Up_BackDown" filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <span>入咽</span>
                <el-select  class="sr" v-model="postdata.S_OP_Json.S_OP_SLP_OD_Up_In" filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <span>分泌物1</span>
                <el-select  class="sr" v-model="postdata.S_OP_Json.S_OP_SLP_OD_Up_Secretion1" filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in options2"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div style="display: flex;align-items: center;justify-content: start;">
                <span>原返</span>
                <el-select class="sr"  v-model="postdata.S_OP_Json.S_OP_SLP_OD_Up_Back" filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <div style="width: 221px;"></div>
                <span>分泌物2</span>
                <el-select  class="sr" v-model="postdata.S_OP_Json.S_OP_SLP_OD_Up_Secretion2" filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in options3"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
          </li>
          <li>
            <p>下冲：</p>
            <div>
              <div style="display: flex;align-items: center;justify-content: start; padding-bottom: 10px;">
                <span>上返</span>
                <el-select class="sr" v-model="postdata.S_OP_Json.S_OP_SLP_OD_Down_BackUp" filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <span>入咽</span>
                <el-select  class="sr" v-model="postdata.S_OP_Json.S_OP_SLP_OD_Down_In" filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <span>分泌物1</span>
                <el-select  class="sr" v-model="postdata.S_OP_Json.S_OP_SLP_OD_Down_Secretion1" filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in options2"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div style="display: flex;align-items: center;justify-content: start;">
                <span>原返</span>
                <el-select class="sr"  v-model="postdata.S_OP_Json.S_OP_SLP_OD_Down_Back" filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <div style="width: 221px;"></div>
                <span>分泌物2</span>
                <el-select  class="sr" v-model="postdata.S_OP_Json.S_OP_SLP_OD_Down_Secretion2" filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in options3"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="nr">
      <p class="yb">OS</p>
      <div>
        <ul>
          <li>
            <p>上冲：</p>
            <div>
              <div style="display: flex;align-items: center;justify-content: start; padding-bottom: 10px;">
                <span>下返</span>
                <el-select class="sr" v-model="postdata.S_OP_Json.S_OP_SLP_OS_Up_BackDown" filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <span>入咽</span>
                <el-select  class="sr" v-model="postdata.S_OP_Json.S_OP_SLP_OS_Up_In" filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <span>分泌物1</span>
                <el-select  class="sr" v-model="postdata.S_OP_Json.S_OP_SLP_OS_Up_Secretion1" filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in options2"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div style="display: flex;align-items: center;justify-content: start;">
                <span>原返</span>
                <el-select class="sr"  v-model="postdata.S_OP_Json.S_OP_SLP_OS_Up_Back" filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <div style="width: 221px;"></div>
                <span>分泌物2</span>
                <el-select  class="sr" v-model="postdata.S_OP_Json.S_OP_SLP_OS_Up_Secretion2" filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in options3"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
          </li>
          <li>
            <p>下冲：</p>
            <div>
              <div style="display: flex;align-items: center;justify-content: start; padding-bottom: 10px;">
                <span>上返</span>
                <el-select class="sr" v-model="postdata.S_OP_Json.S_OP_SLP_OS_Down_BackUp" filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <span>入咽</span>
                <el-select  class="sr" v-model="postdata.S_OP_Json.S_OP_SLP_OS_Down_In" filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <span>分泌物1</span>
                <el-select  class="sr" v-model="postdata.S_OP_Json.S_OP_SLP_OS_Down_Secretion1" filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in options2"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div style="display: flex;align-items: center;justify-content: start;">
                <span>原返</span>
                <el-select class="sr"  v-model="postdata.S_OP_Json.S_OP_SLP_OS_Down_Back" filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <div style="width: 221px;"></div>
                <span>分泌物2</span>
                <el-select  class="sr" v-model="postdata.S_OP_Json.S_OP_SLP_OS_Down_Secretion2" filterable allow-create  placeholder="请选择">
                  <el-option
                      v-for="item in options3"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "lacrimalirrigation",
  data() {
    return {
      value: '',
      options: [
        {
          value: '部分',
          label: '部分'
        },
        {
          value: '全部',
          label: '全部'
        },
        {
          value: '',
          label: ''
        }
      ],
      options2: [
        {
          value: '无',
          label: '无'
        },
        {
          value: '少量',
          label: '少量'
        },
        {
          value: '大量',
          label: '大量'
        },
        {
          value: '',
          label: ''
        }
      ],
      options3: [
        {
          value: '脓性',
          label: '脓性'
        },
        {
          value: '粘液性',
          label: '粘液性'
        },
        {
          value: '',
          label: ''
        }
      ],
      postdata: {
        S_OP_ChooseId: this.$store.state.physicianVisits.xzConsulting,
        S_OP_CustomerId: this.$store.state.users.CsUser.CustomerId,
        S_OP_ExpertId: this.$store.state.users.employees?this.$store.state.users.employees.ExpertId:'00000000-0000-0000-0000-000000000000',
        S_OP_ID: "00000000-0000-0000-0000-000000000000",
        S_OP_Json:{
          S_OP_IO_ChooseId: this.$store.state.physicianVisits.xzConsulting,
          S_OP_SLP_OD_Down_Secretion1: '',
          S_OP_SLP_OD_Down_Secretion2: '',
          S_OP_SLP_OD_Down_BackUp: '',
          S_OP_SLP_OD_Down_Back: '',
          S_OP_SLP_OD_Down_In: '',
          S_OP_SLP_OD_Up_Secretion1: '',
          S_OP_SLP_OD_Up_Secretion2: '',
          S_OP_SLP_OD_Up_BackDown: '',
          S_OP_SLP_OD_Up_Back: '',
          S_OP_SLP_OD_Up_In: '',

          S_OP_SLP_OS_Down_Secretion1: '',
          S_OP_SLP_OS_Down_Secretion2: '',
          S_OP_SLP_OS_Down_BackUp: '',
          S_OP_SLP_OS_Down_Back: '',
          S_OP_SLP_OS_Down_In: '',
          S_OP_SLP_OS_Up_Secretion1: '',
          S_OP_SLP_OS_Up_Secretion2: '',
          S_OP_SLP_OS_Up_BackDown: '',
          S_OP_SLP_OS_Up_Back: '',
          S_OP_SLP_OS_Up_In: '',
        },
        S_OP_Model: "S_OP_Santong230216",
        S_OP_Type: "S_OP_SLP"
      }
    }
  },
  watch: {
    "postdata": {
      deep: true,
      handler(n, o) {
        // console.log(n)
        this.$store.commit('physicianVisits/uplacrimalirrigation', n)
      }
    }
  },
  created() {
    this.$store.commit('physicianVisits/uplacrimalirrigation', this.postdata)
    this._http.post(this._getUrl('PS_all_Normal_getList'), {
      "token": {
        "TimeSpan": 1566827733,
        "sToken": "768DCAFFF869CD61372790C2738012EC"
      },
      SearchCondition: {
        S_OP_Type: 'S_OP_SLP',
        ChooseId: this.$store.state.physicianVisits.xzConsulting
      }
    })
        .then(res => {
          if (res.GetListResult) {
            this.postdata.S_OP_Json = this._clJson(res.GetListResult[0].S_OP_Json)
          }
        })
  }
}
</script>

<style scoped lang="scss">
.lacrimalirrigation {
  width: 90%;
  margin: 0 auto;
}
h4 {
  text-align: left;
  padding-bottom: 20px;
}

.nr {
  display: flex;
  align-items: center;
  padding-top: 30px;
  .yb {
    padding-right: 20px;
  }
  li {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    //span {padding-right: 5px; padding-left: 15px;}
    .sr {
      width: 170px;
      margin-right: 15px;
      margin-left: 5px;
    }
    li:nth-child(2) {
      padding-bottom: 0;
    }
  }
}

</style>
